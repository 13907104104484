import React, { useEffect, useState } from "react";
import { Layout, Menu, Alert, Button, Badge } from "antd";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
const { Header, Content, Sider, Footer } = Layout;
//assets
//type imports
import { navItem, navItems } from "../../nav/navData";
import { Link } from "react-router-dom";
import { Location } from "history";
import Colors from "../../constants/Colors";
import { Store } from "../../redux/reducers/StoreReducer";
import Cookies from "js-cookie";
import { BugTwoTone } from "@ant-design/icons";
import "./SidebarLayout.css";
import { AppState } from "../../redux/reducers/AppReducer";
import { useAppSelector } from "../../redux/hooks";
import { HeaderLite, MenuButton, MobileDrawer } from "./ConsignerNavLayout";
import { getInventoryStatusFromAPI } from "../../redux/actions/inventoryActions";
import { ExportOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

/*
  Sidebar Layout
  Main sidebar used on authenticated routes
  - Handles selected side menu item via react router location path and matched to menu item key
  - Renders all children inside content
  - Takes List of Navitems to render on side bar
*/
//styles
const Logo = styled.img`
  width: 100px;
  height: 64px;
  object-fit: contain;
`;
const Container = styled(Layout)`
  height: 100vh;
  overflow: hidden;
  overflow-y: hidden;
`;
const LogoContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items; center;
    border-bottom: 1px solid #e6e5e5;
`;
const TopHeader = styled(Header)`
  background: ${Colors.WHITE};
  border-bottom: 1px solid #e6e5e5;
`;

const HeaderAlert = styled(Alert)`
  padding: 6px;
`;

//types
interface Props {
  children: JSX.Element;
  navItems: navItem[];
  logo: string;
  store: Store;
}

const getRealLabel = (label: string): string => {
  switch (label) {
    case "Requested":
      return "Requested";
    case "Drop Off Pending":
      return "Pending";
    case "Price Change":
      return "RequestPriceChange";
    case "Withdrawal Request":
      return "RequestWithdrawal";
    default:
      return label;
  }
};

//helpers
export const isCurrentPath = (item: navItem, location: Location): boolean => {
  return item.path === location.pathname;
};
export const findCurrentSubMenu = (
  navItem: navItem,
  location: Location
): navItem | undefined => {
  return navItem.subMenu?.find((item) => isCurrentPath(item, location));
};
export const getOpenKeys = (
  navItems: navItem[],
  location: Location | Location<unknown>
): string[] => {
  //Checks if nav items sub menu has the current path, and returns the submenu item path to make sure it stays open
  const navItemsToOpen = navItems.filter((navItem) =>
    findCurrentSubMenu(navItem, location)
  );
  return navItemsToOpen.map((item) => item.path);
};
//helper components
type renderMenuItemProp = (
  navItem: navItem,
  statusTypes?: Array<any>
) => JSX.Element;

export const renderMenuItem = (
  navItem: navItem,
  statusTypes?: Array<any>
): JSX.Element => (
  <Menu.Item data-testid="menuItem" key={navItem.path} icon={navItem.icon}>
    <Link
      data-testid="menuItemLink"
      to={navItem.path}
      onClick={navItem.onClick}
    >
      {navItem.label}
    </Link>
    {statusTypes &&
      [
        "Requested",
        // "Drop Off Pending",
        "Price Change",
        "Withdrawal Request",
        "Buying",
        "Consignment",
      ].includes(navItem.label) && (
        <Button
          type="primary"
          shape="circle"
          style={{ position: "absolute", top: 0, right: 5 }}
          // color="#67abf4"
        >
          {statusTypes?.find(
            (status) => status.status === getRealLabel(navItem.label)
          )?.count ?? ""}
        </Button>
      )}
  </Menu.Item>
);

export const renderSubMenuItem = (
  navItem: navItem,
  renderMenuItem: renderMenuItemProp
) => (
  //rendering a submenu, adds a submenu wrapper and create navitems inside
  <Menu.SubMenu
    data-testid="subMenuItem"
    key={navItem.path}
    icon={navItem.icon}
    title={navItem.label}
  >
    {navItem.subMenu?.map((subMenuItem) => renderMenuItem(subMenuItem))}
  </Menu.SubMenu>
);

export const renderNavItem = (
  navItem: navItem,
  renderMenuItem: renderMenuItemProp,
  renderSubMenuItem: (
    navItem: navItem,
    renderMenuItem: renderMenuItemProp
  ) => JSX.Element,
  statusTypes?: Array<any>
): JSX.Element => {
  //if has a submenu, will render a submenu
  return navItem.subMenu
    ? renderSubMenuItem(navItem, (nav) => renderMenuItem(nav, statusTypes))
    : renderMenuItem(navItem, statusTypes);
};

const SidebarLayout = (props: Props) => {
  const { children, navItems, logo, store } = props;
  const location = useLocation();
  const [isTopHeaderVisible, setIsTopHeaderVisible] = React.useState(true);
  const [showMenu, setShowMenu] = useState(false);
  const { isMobile }: AppState = useAppSelector((state) => state.AppReducer);
  const [statusCount, setStatusCount] = useState<any[]>([]);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  useEffect(() => {
    const topHeaderVisibility = Cookies.get("topHeaderVisibility");
    if (topHeaderVisibility === "hidden") {
      setIsTopHeaderVisible(false);
    }
    getInventoryStatusFromAPI().then((data) => {
      setStatusCount(data.status);
    });
  }, []);

  // useEffect(() => {
  //   console.log(statusCount);
  // }, [statusCount]);

  return (
    <Layout
      style={{
        overflowY: "scroll",
        minHeight: "100vh",
        backgroundColor: Colors.ADMIN_NEUTRAL,
      }}
    >
      {isTopHeaderVisible && (
        <>
          <TopHeader
            style={{
              padding: 0,
              position: "relative",
            }}
          >
            {store && process.env.REACT_APP_TYPE != "consigner" && (
              <Alert
                message="Notice"
                description={
                  <div>
                    <div>
                      We're collecting feedback on the customer experience using
                      Trace and wanted to hear from you! Your feedback is
                      incredibly important to us and will help us make the
                      product and user experience even better going forward -
                      <a href="https://docs.google.com/forms/d/e/1FAIpQLSfy1aa2V-CII0cPN4LGIJpdGuONA_JdIwGgd_JxEzgTJZGu4Q/viewform">
                        Here
                      </a>
                    </div>
                  </div>
                }
                type="info"
                showIcon
                closable
                style={{ zIndex: 999 }}
                onClose={() => {
                  // set cookie to keep message closed for 30 minutes
                  setIsTopHeaderVisible(false);
                  Cookies.set("topHeaderVisibility", "hidden", {
                    expires: 1,
                  });
                }}
              />
            )}
          </TopHeader>
        </>
      )}
      <Layout
        style={{ width: "100%", backgroundColor: "inherit" }}
        hasSider={!isMobile}
      >
        {isMobile ? (
          <HeaderLite
            className="header"
            style={{
              justifyContent: isMobile ? "space-between" : "flex-start",
              position: "relative",
              maxHeight: 60,
            }}
          >
            <LogoContainer style={{ order: isMobile ? "2" : "1" }}>
              <Logo src={logo} />
            </LogoContainer>
            <MenuButton style={{ order: 1 }} onClick={toggleMenu} />
            <span style={{ order: 3, width: 50 }}></span>
          </HeaderLite>
        ) : (
          <Sider theme="light" breakpoint="lg" collapsedWidth="0">
            <LogoContainer style={{ paddingTop: 17 }}>
              <Logo data-testid="logo" src={logo} />
            </LogoContainer>
            <Menu
              data-testid="menu"
              theme="light"
              mode="inline"
              defaultSelectedKeys={["0"]}
              selectedKeys={[location.pathname]}
              defaultOpenKeys={getOpenKeys(navItems, location)}
            >
              {navItems.map((navItem) =>
                renderNavItem(
                  navItem,
                  renderMenuItem,
                  renderSubMenuItem,
                  statusCount
                )
              )}
              <div
                style={{
                  position: "relative",
                  cursor: "pointer",
                  left: "1rem",
                  fontWeight: "600",
                }}
              >
                <Tooltip title="Check New Updates">
                  <a
                    href="https://app.getbeamer.com/trace/en"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      backgroundColor: "#67abf4",
                      color: "white",
                      padding: "10px 20px",
                      borderRadius: "10px",
                      display: "inline-flex",
                      alignItems: "center",
                    }}
                  >
                    New Updates
                    <ExportOutlined style={{ marginLeft: "0.5em" }} />
                  </a>
                </Tooltip>
              </div>
            </Menu>
          </Sider>
        )}
        <MobileDrawer
          toggleMenu={toggleMenu}
          showMenu={showMenu}
          navItems={navItems}
        />
        <Content
          style={{
            // paddingTop: 17,
            order: isMobile ? 2 : 1,
            //   margin: isTopHeaderVisible ? "40px 16px 0" : "24px 16px 0",
          }}
        >
          {children}
        </Content>
      </Layout>
      {/* <Footer style={{ textAlign: "center" }}>
        <>
          Powered By{" "}
          <a
            href="https://fulltrace.co/"
            target="_blank"
            rel="noopener noreferrer"
          >
            TRACE
          </a>{" "}
        </>
      </Footer> */}
    </Layout>
  );
};

export default SidebarLayout;
